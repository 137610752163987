import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, Alert, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { api } from '../api';
import { PersonAdd, Email, LockOpen } from '@mui/icons-material';
import { AuthContext } from '../App';
import { GoogleLogin } from '@react-oauth/google';
import { setAuthToken } from '../utils/auth';

function SignUpPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { setIsAuthenticated } = useContext(AuthContext);

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        setPasswordError('');

        try {
            const response = await api.post('/register', { email, password });

            // Check if we have the access token in the response
            if (response.data && response.data.access_token) {
                // Set the token in localStorage
                setAuthToken(response.data.access_token);
                // Update authentication state
                setIsAuthenticated(true);
                // Show success message
                setSuccess('Account created successfully!');
                // Navigate to brdges page
                navigate('/brdges', { replace: true });
            } else {
                console.error('No access token in response:', response);
                setError('Registration successful but login failed. Please try logging in.');
                navigate('/login');
            }
        } catch (err) {
            console.error('Registration error:', err);
            setError(err.response?.data?.error || 'An error occurred during registration');
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            if (!credentialResponse.credential) {
                setError('No credentials received from Google');
                return;
            }

            const response = await api.post('/auth/google', {
                credential: credentialResponse.credential
            });

            if (response.data.access_token) {
                setAuthToken(response.data.access_token);
                setIsAuthenticated(true);
                setSuccess('Successfully signed up with Google!');
                navigate('/brdges', { replace: true });
            } else {
                throw new Error('No access token received');
            }
        } catch (error) {
            console.error('Google sign-up error:', error.message);
            setError(error.response?.data?.error || 'Failed to login with Google');
        }
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #000B1F 0%, #001E3C 50%, #0041C2 100%)',
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '5%',
                left: '-5%',
                width: '600px',
                height: '600px',
                background: 'radial-gradient(circle, rgba(79, 156, 249, 0.1) 0%, transparent 70%)',
                borderRadius: '50%',
                filter: 'blur(80px)',
                animation: 'float 20s infinite alternate'
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '5%',
                right: '-5%',
                width: '500px',
                height: '500px',
                background: 'radial-gradient(circle, rgba(0, 180, 219, 0.1) 0%, transparent 70%)',
                borderRadius: '50%',
                filter: 'blur(80px)',
                animation: 'float 25s infinite alternate-reverse'
            }
        }}>
            {/* Geometric shapes */}
            <Box sx={{
                position: 'absolute',
                top: '10%',
                left: '15%',
                width: '400px',
                height: '400px',
                border: '1px solid rgba(255,255,255,0.1)',
                transform: 'rotate(45deg)',
                animation: 'rotate 30s linear infinite',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: -1,
                    padding: '1px',
                    background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.2))',
                    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    WebkitMaskComposite: 'xor',
                    maskComposite: 'exclude'
                }
            }} />
            <Box sx={{
                position: 'absolute',
                bottom: '15%',
                right: '10%',
                width: '300px',
                height: '300px',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '50%',
                animation: 'rotateReverse 25s linear infinite',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: -1,
                    borderRadius: 'inherit',
                    padding: '1px',
                    background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.2))',
                    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    WebkitMaskComposite: 'xor',
                    maskComposite: 'exclude'
                }
            }} />

            <Container maxWidth="xs" sx={{
                position: 'relative',
                zIndex: 1,
                mt: { xs: 8, md: 12 }
            }}>
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography
                            variant={isMobile ? "h3" : "h2"}
                            component="h1"
                            align="center"
                            sx={{
                                mb: { xs: 3, md: 4 },
                                fontFamily: 'Satoshi',
                                fontWeight: '600',
                                fontSize: { xs: '2rem', sm: '2.75rem', md: '3.5rem' },
                                color: 'white',
                                textTransform: 'none',
                                letterSpacing: '-0.02em',
                                lineHeight: 1.1,
                                position: 'relative',
                                textShadow: '0 0 40px rgba(34, 211, 238, 0.25)',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-16px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '120px',
                                    height: '1px',
                                    background: 'linear-gradient(90deg, transparent, rgba(34, 211, 238, 0.3), transparent)',
                                    borderRadius: '1px',
                                    boxShadow: '0 0 10px rgba(34, 211, 238, 0.2)'
                                }
                            }}
                        >
                            Sign Up
                        </Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                mb: 2,
                                fontFamily: 'Satoshi',
                                color: 'rgba(255, 255, 255, 0.8)',
                                maxWidth: '300px',
                                mx: 'auto',
                                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                                fontWeight: '400',
                                letterSpacing: '0.01em',
                                lineHeight: 1.6
                            }}
                        >
                            Create your free account today
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                mb: 3,
                                fontFamily: 'Satoshi',
                                textAlign: 'center',
                                maxWidth: '90%',
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                                lineHeight: 1.5
                            }}
                        >
                            Try Brdge AI and <strong>experience the future</strong> of interactive content.
                        </Typography>

                        {/* Form Container */}
                        <Box sx={{
                            width: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.02)',
                            backdropFilter: 'blur(20px)',
                            borderRadius: '24px',
                            p: 4,
                            border: '1px solid rgba(34, 211, 238, 0.1)',
                            boxShadow: '0 4px 30px rgba(34, 211, 238, 0.05)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '1px',
                                background: 'linear-gradient(90deg, transparent, rgba(34, 211, 238, 0.1), transparent)'
                            }
                        }}>
                            {error && (
                                <Alert
                                    severity="error"
                                    sx={{
                                        mb: 2,
                                        backgroundColor: 'rgba(255, 107, 107, 0.1)',
                                        color: '#ff6b6b',
                                        border: '1px solid rgba(255, 107, 107, 0.2)',
                                        '& .MuiAlert-icon': {
                                            color: '#ff6b6b'
                                        }
                                    }}
                                >
                                    {error}
                                </Alert>
                            )}
                            {success && (
                                <Alert
                                    severity="success"
                                    sx={{
                                        mb: 2,
                                        backgroundColor: 'rgba(84, 214, 44, 0.1)',
                                        color: '#54d628',
                                        border: '1px solid rgba(84, 214, 44, 0.2)',
                                        '& .MuiAlert-icon': {
                                            color: '#54d628'
                                        }
                                    }}
                                >
                                    {success}
                                </Alert>
                            )}

                            <Box sx={{
                                width: '100%',
                                mb: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                '& > div': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }
                            }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    onError={() => setError('Google Sign In failed. Please try again.')}
                                    useOneTap={false}
                                    theme="filled_blue"
                                    size="large"
                                    text="signup_with"
                                    shape="rectangular"
                                    width={isMobile ? "250" : "300"}
                                />
                            </Box>

                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                mb: 3
                            }}>
                                <Box sx={{ flex: 1, height: '1px', background: 'rgba(255, 255, 255, 0.1)' }} />
                                <Typography sx={{ px: 2, color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.875rem' }}>
                                    or sign up with email
                                </Typography>
                                <Box sx={{ flex: 1, height: '1px', background: 'rgba(255, 255, 255, 0.1)' }} />
                            </Box>

                            <form onSubmit={handleSignUp}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    required
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <Email sx={{ mr: 1, color: theme.palette.primary.main }} />,
                                    }}
                                    sx={{
                                        mb: 2,
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.02)',
                                            borderRadius: '12px',
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                                transition: 'all 0.2s ease-in-out',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(79, 156, 249, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#4F9CF9',
                                                borderWidth: '1px',
                                                boxShadow: '0 0 10px rgba(79, 156, 249, 0.2)',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.6)',
                                            '&.Mui-focused': {
                                                color: '#4F9CF9',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    required
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        if (confirmPassword && e.target.value !== confirmPassword) {
                                            setPasswordError('Passwords do not match');
                                        } else {
                                            setPasswordError('');
                                        }
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <LockOpen sx={{ mr: 1, color: theme.palette.primary.main }} />,
                                    }}
                                    sx={{
                                        mb: 2,
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.02)',
                                            borderRadius: '12px',
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                                transition: 'all 0.2s ease-in-out',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(79, 156, 249, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#4F9CF9',
                                                borderWidth: '1px',
                                                boxShadow: '0 0 10px rgba(79, 156, 249, 0.2)',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.6)',
                                            '&.Mui-focused': {
                                                color: '#4F9CF9',
                                            },
                                        },
                                    }}
                                />
                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    fullWidth
                                    required
                                    margin="normal"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        if (password !== e.target.value) {
                                            setPasswordError('Passwords do not match');
                                        } else {
                                            setPasswordError('');
                                        }
                                    }}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <LockOpen sx={{ mr: 1, color: theme.palette.primary.main }} />,
                                    }}
                                    sx={{
                                        mb: 3,
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.02)',
                                            borderRadius: '12px',
                                            '& fieldset': {
                                                borderColor: 'rgba(255, 255, 255, 0.1)',
                                                transition: 'all 0.2s ease-in-out',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(79, 156, 249, 0.5)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#4F9CF9',
                                                borderWidth: '1px',
                                                boxShadow: '0 0 10px rgba(79, 156, 249, 0.2)',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.6)',
                                            '&.Mui-focused': {
                                                color: '#4F9CF9',
                                            },
                                        },
                                    }}
                                />
                                <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                        startIcon={<PersonAdd />}
                                        disabled={!!passwordError}
                                        sx={{
                                            py: 1.5,
                                            borderRadius: '50px',
                                            background: 'linear-gradient(45deg, rgba(34, 211, 238, 0.8), rgba(34, 211, 238, 0.6))',
                                            fontSize: '1rem',
                                            fontFamily: 'Satoshi',
                                            fontWeight: '600',
                                            letterSpacing: '0.02em',
                                            textTransform: 'none',
                                            transition: 'all 0.3s ease-in-out',
                                            boxShadow: '0 4px 15px rgba(34, 211, 238, 0.2)',
                                            '&:hover': {
                                                background: 'linear-gradient(45deg, rgba(34, 211, 238, 0.9), rgba(34, 211, 238, 0.7))',
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 6px 20px rgba(34, 211, 238, 0.4)',
                                            },
                                        }}
                                    >
                                        Get Started Free
                                    </Button>
                                </motion.div>
                            </form>
                        </Box>

                        <Box mt={3}>
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.5)',
                                    fontSize: '0.8rem',
                                    mb: 2
                                }}
                            >
                                By signing up, you acknowledge and agree to our{' '}
                                <Link
                                    to="/policy#privacy"
                                    style={{
                                        color: 'rgba(79, 156, 249, 0.8)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Privacy Policy
                                </Link>
                                {' '}and{' '}
                                <Link
                                    to="/policy#terms"
                                    style={{
                                        color: 'rgba(79, 156, 249, 0.8)',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Terms of Service
                                </Link>
                            </Typography>
                            <Typography variant="body1" align="center" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                Already have an account?{' '}
                                <Link
                                    to="/login"
                                    style={{
                                        color: '#4F9CF9',
                                        fontWeight: 'bold',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Log in
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </motion.div>
            </Container>

            <style>
                {`
                    @keyframes float {
                        0% { transform: translateY(0px) rotate(0deg); }
                        50% { transform: translateY(-20px) rotate(5deg); }
                        100% { transform: translateY(0px) rotate(0deg); }
                    }
                    @keyframes rotate {
                        from { transform: rotate(0deg); }
                        to { transform: rotate(360deg); }
                    }
                    @keyframes rotateReverse {
                        from { transform: rotate(360deg); }
                        to { transform: rotate(0deg); }
                    }
                `}
            </style>
        </Box>
    );
}

export default SignUpPage;
